.accountRow {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    
    border-bottom: 1px solid var(--separator-color);

}

.accountRow:hover {
    background-color: rgb(216, 216, 216);
}

.accountRow > * {
    margin: 20px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    
}

.editIconContainer {
    margin-left: auto;
}

.editIcon {
    font-size: 20px;
    color: blue;
}

.editIcon:hover {
    cursor: pointer;
}