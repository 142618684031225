.landingPageTitle{
    font-size: 94px;
    text-align: center;
    font-weight: bold;
    font-family: sohne-var, "Helvetica Neue", Arial, sans-serif;
    white-space: pre-wrap;
    width: 1200px;
    margin-top: 100px;
}

@media (max-width: 1200px) {
    .landingPageTitle {
        font-size: 72px;
    }
  }

@media (max-height: 900px) {
    .landingPageTitle {
        font-size: 72px;
    }
  }

@media (max-height: 800px) {
    .landingPageTitle {
        font-size: 52px;
    }
  }