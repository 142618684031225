.journalPage {
    width: 80%;
    height: 100vh;
    margin-left: auto;
    margin-right: auto;
    position: relative;
    display: "flex";
    flex-direction: column;
    right: -125px;
}

.journalDateAndDescription {
    display: "flex", 
}

.journalTableHeader {
    background-color: rgb(236 240 243);
    border: 1px solid rgb(212 221 227);
 }

 .journalRow {
    border-bottom: 1px solid rgb(212 221 227);
    height: 45px;
 }

 .journalRow:hover {
    background-color: rgb(230, 241, 255);
}
 .journalRow:hover .debitCreditSwapButton {
    opacity: 1;
}

.journalDelete:hover {
    color: #d11a2a;
    cursor: pointer;
}

 .journalTotalBox {
    background-color: rgb(245, 250, 255);
    height: 150px;
    width: 250px;
    border-radius: 10px;
 }


 .debitCreditSwapButton {
    transition: 0.1s;
    opacity: 0;
}

.debitCreditSwapButton:hover {
    cursor: pointer;
    color: rgb(62, 62, 62);
    box-shadow: 0 0 10px rgb(201, 201, 201);
    border-radius: 50%;
    /* transform: rotateZ(180deg); */
}

.deleteJournalTransaction:hover {
    color: #a50e1a !important;
    cursor: pointer;
}