.contactForm {
    margin: auto;
    width: 35%;
}

.billing {
    margin: auto;
    width: 90%;
}

.shipping{
    margin: auto;
    width: 90%;
}

.submitContact {
    display: flex;
    justify-content: flex-end;
}

.submitContactTop {
    margin-right: 5%;
}

.formHeader {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.addContactIcon{
    color: #90a790;
    font-size: 22px;
}

.addContactIcon:hover, .addContactLinkActive{
    
    color: #8bc98e;
    cursor: pointer;
    text-decoration: underline;
   
}

.deleteIcon{
    font-size: 24px;
    color: #d11a2a;
    margin-top: -24px;
    float: right;
    
}

.deleteIcon:hover{
    color: darkred;
    cursor: pointer;
}