.tileButton {
    transition: 0.2s;
    width: 192px;
    height: 224px;
    border-radius: 12px;
    border: 1px solid rgb(180, 194, 203);
    margin: 10px;
    background-color: white;
}

.tileButton:hover, .tileButtonRaised:hover{
    box-shadow: 0 0 20px rgb(201, 201, 201);
    transform: scale(1.05, 1.05);
    transform: translateY(-5px);
    border: 1px solid rgba(180, 194, 203, 0);
    cursor: pointer;
}

.tileContainer{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    height: calc(224px - 60px);
    margin: 30px
}

.tileTitle{
 font-weight: bold;
 font-size: 20px;
 white-space: normal;
 text-align: center;
}

.tileImage{
    margin-top: 10px;
}