body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont,  'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    Helvetica;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

:root {
  --page-margin: 0.5in;
  --selected-color: #01c625;
  --separator-color: rgb(212 221 227);
}

/* .form-control:focus{
  outline: 0 !important;
  box-shadow: 0 0 0 .25rem rgba(108, 108, 109, 0.25) !important;
} */