.topDashboardContainer {
    display: flex;
    justify-content: space-between;
    width: 1550px;
}

@media (max-width: 1860px) {
    .topDashboardContainer {
        display: flex;
        flex-direction: column;
    }
  }