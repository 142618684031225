.accountHeader {
    /* background-color: rgb(224, 231, 235); */
    /* background: linear-gradient(to bottom, rgb(224, 231, 235), rgb(192, 200, 205) 80%); */
    /* background: linear-gradient(90deg, rgba(14,117,205,1) 0%, rgba(94,94,233,1) 45%, rgba(0,212,255,1) 87%); */
    background: linear-gradient(0deg, rgba(146,170,146,1) 0%, rgba(204,226,205,1) 45%, rgba(199,221,199,1) 87%);
}

.addAccountButton {
    color: rgb(025, 135, 084);
    width: 100px;
}

.addAccountButton:hover {
    cursor: pointer;
    text-decoration: underline;
    color: rgb(23, 156, 94);
}