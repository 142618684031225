.productsList {
    width: 950px;
    margin-left: auto;
    margin-right: auto;
    position: relative;
    right: -125px;
}

.productsAndServicesTable {
    width: 100%;
}



@media (max-width: 1200px) {
    .productsList {
        width: 850px;
        right: 0px;
    }
}

@media (max-width: 1000px) {
    .productsList {
        width: 700px; 
        right: 0px;
    }
}