.addProducts {
    display: flex;
    flex-direction: column;
    width: 1000px;
    margin-left: auto;
    margin-right: auto;
}

.addProducts .formItem {
    width: 500px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 10px;
}