.searchableDropdownWithCreate{
    width: 200px;
    user-select: none;
    box-shadow: 0 0 20px rgb(201, 201, 201);
    border-radius: 5px;
    border: 2px solid #90a790;
    background-color: white;
    color: black;
}

.search{
    padding: 0.5rem;
    position: relative;
    display: flex;
    align-items: center;
    width: 100%;
}

.searchBar {
    padding: 0.5rem 0.5rem 0.5rem 3rem;
    width: 100%;
}

.scrollableList{
    padding: 0.5rem;
    overflow-y: auto;
    max-height: 200px;
}

.scrollableListItem{
    display: 'flex';
    justify-content: space-between;
}

.scrollableListItem:hover {
    
    background-color: rgb(216, 216, 216);
    cursor: pointer;
    user-select: none;
}
.createButton{
    text-align: center;
}

.createButton {
    display: flex;
    justify-content: space-between;
}
.createButton:hover {
    background-color: #cce2cd;
    color: rgb(61, 61, 61);
    cursor: pointer;
}


