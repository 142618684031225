
.invoiceForm {
    font-family: Georgia !important; 
    font-size: 15px;
    margin-left: auto;
    margin-right: auto;
    width: 765px;
    height: 990px;
    
    border-radius: 5px;
    display: flex;
    flex-direction: column;

}

.invoiceHeader { 
    display: flex;
    align-items: center;
    width: calc(100% - var(--page-margin) - var(--page-margin));
    height: 160px;
    margin-left: var(--page-margin);
    margin-right: var(--page-margin);
    border-bottom: solid;
    border-width: 1px 0;
    border-top: none;
}

.secondaryInvoiceHeader {
    display: flex;
    justify-content: space-between;
}

.invoiceLogoWrapper {
    height: 75%;
    min-width: 25%;
    margin: var(--page-margin) 30px auto 0px;
    text-align: center;

    color: rgba(164, 159, 159, 0.839);
    font-size: 12px;

}

.invoiceFromAddress {
    display: flex;
    flex-direction: column;
    align-items:flex-start;
    justify-content: flex-start;
    height: 80%;
    width: 50%;
    margin: var(--page-margin) var(--page-margin) auto var(--page-margin);
}

.invoiceFromAddress .invoiceCompany {
    font-weight: bold;
}

.invoiceFromAddress .phoneWebsiteEmail {
    width: 100%;
    display: flex;
}

.invoiceFromAddress .phoneWebsiteEmail > *{
    margin-right: 20px;
}


/*------------------------------------ */

.invoiceInfo { 
    display: flex;
    width: 100%;
    height: 170px;
    margin-top: 10px;
}

.paymentInfo {
    display: inline-block;
    width: calc(50% - 30px - var(--page-margin));
    height: 80%;
    margin: calc(2rem - 5px) 30px auto var(--page-margin);
}

.paymentInfo div label {
    text-align: left;
    width: 40%;
}

.paymentInfo div text {
    width: 60%;
}

.paymentInfoRow {
    margin-top: -5px;
}

.billingInfo {
    width: 50%;
    height: 80%;
    margin: 0 var(--page-margin) auto 0px;
}

.billingInfoRow {
    margin-top: -5px;
}

/*------------------------------------ */

.invoiceItems {
    display: flex;
    flex-direction: column;
    width: 100%;
    min-height: 80px;
}

.invoiceItems h3 {
    text-align: center;
    margin-bottom: 20px;
    
}

.invoiceItemList {
    width: calc(100% - var(--page-margin) - var(--page-margin));
    min-height: 40px;
    margin-left: var(--page-margin);
    margin-right: var(--page-margin);
}
/* 
.invoiceItemList table tbody:hover{
    background-color: #ddd;
} */

.invoiceItemList table tbody:hover tr > .rowDelete {
    opacity: 1 !important;
    cursor: pointer;
    color: black;
}

.invoiceItemList table tbody tr .rowDelete:hover {
    color: #d11a2a;
}


tbody.invoiceTableBody tr td:not(:first-child) {
    text-align: right;
}

thead.invoiceTableHead th:not(:first-child) {
    text-align: right;
}

.discountOrTaxButton {
    border: none;
    background-color: white;
    color: #cce2cd;
}

.discountOrTaxButton:hover {
    text-decoration: underline;
    cursor: pointer;
    color: #8bc98e;
}

.addTaxRow {
    display: flex;
    justify-content: flex-start;
    margin-left: 30px;
    color: #cce2cd;
    max-width: 4rem;
}

.addTaxRow:hover {
    text-decoration: underline;
    cursor: pointer;
    color: #8bc98e;
}

.taxRow {
    display: flex;
    justify-content: flex-start;
}

.itemTax {
    display: block;
    text-align: right;
}

.taxSubtotal {
    margin-left: auto;
}

.taxDelete {
    opacity: 0;
    margin: 5px;
}

.taxRow:hover  .taxDelete{
    opacity: 1 !important;
    color: black;
}

.taxDelete:hover {
    color: #d11a2a !important;
    cursor: pointer;
}

.notesSection {
    color: rgb(161, 161, 161);
    font-style: italic;
}


/*------------------------------------ */

.invoiceTotal {
    width: 100%;
    min-height: 180px;
    display: flex;
    justify-content: space-between;
}

.movableInvoiceTotals {
    width: 100%;
    min-height: 160px;
    display: flex;
    justify-content: flex-end;
}

.addItemRow {
    width: 20%;
    margin-left: var(--page-margin);
    margin-top: 10px;
    color: #cce2cd;
}

.addItemRow:hover {
    text-decoration: underline;
    cursor: pointer;
    color: #8bc98e;
}

.amountDue {
    height: 80%;
    width: 40%;
    margin-right: var(--page-margin);
    margin-top: 0px;
}

.amountDue table tr td {
    text-align: right;
}

hr.solid {
    width: 100%;
    border-top: 3px solid #bbb;
  }

  .invoiceTotalAmount, .invoicePaidAmount, .invoiceDueAmount{
    font-weight: bold;
  }


  .globalTotalChanger:hover th > .globalChangerDelete{
    opacity: 1 !important;
    color: black;
}

.globalChangerDelete:hover {
    color: #d11a2a !important;
    cursor: pointer;
}
/*------------------------------------ */

.invoiceFooter {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;

    width: 50%;
    margin-top: auto;
}

.secondaryInvoiceFooter  {
    display: flex;
    justify-content: space-between;
    margin: 0px var(--page-margin) var(--page-margin) var(--page-margin);
}

.secondaryInvoiceHeader  {
    display: flex;
    justify-content: space-between;
    margin: var(--page-margin) var(--page-margin) 10px var(--page-margin);
}

.notesAndTerms {
    width: calc(100% - var(--page-margin));
    height: 40%;
    margin-top: -100px;
    margin-left: var(--page-margin);
}



