.recurringSchedule {
    position: absolute;
    right: 50px;
    top: 25%;

}

.recurringForm {
    font-family: Georgia;
    font-size: 15px;
    margin-right: 500px;
}

.responsiveHeader {
    width: 800px;
}

@media (max-width: 1700px) {
    .recurringSchedule {
        position: static;
        margin-left: auto;
        margin-right: auto;
    }
    .recurringForm {
        margin-right: 0px;
    }
  }