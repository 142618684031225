.transactionsPage {
    width: 80%;
    height: 100vh;
    margin-left: auto;
    margin-right: auto;
    position: relative;
    display: "flex";
    flex-direction: column;
    right: -125px;
}

.transactionsFilterBar {
    background-color: rgb(236 240 243);
    width: "100%";
}

.transactionsTableHeader {
   background-color: rgb(236 240 243);
   border: 1px solid var(--separator-color);
}

.selectAll:hover {
    cursor: pointer;
}

.transactionRow {
    border: 1px solid rgb(212 221 227);
}

.transactionRow:hover {
    background-color: rgb(230, 241, 255);
}

.actionDropdown {
    font-size: 30px;
}

.actionDropdown:hover {
    cursor: pointer;
}

@media (max-width: 1200px) {
    .transactionsPage {
        right: 0px;
        width: 90%;
    }
  
  }


  .split-arrows-enter {
    opacity: 0;
    transform: scale(0);
  }
  .split-arrows-enter-active {
    opacity: 1;
    transform: scale(1);
    transition: opacity 300ms, transform 300ms;
  }
  .split-arrows-exit {
    transform: scale(1);
    opacity: 1;
  }
  .split-arrows-exit-active {
    opacity: 0;
    transform: scale(0);
    transition: opacity 300ms, transform 300ms;
  }
  

.swap-arrow-Deposit {
    transition: 0.2s;
    transform: rotateZ(180deg);
}

.swap-arrow-Withdrawal {
    transition: 0.2s;
    transform: rotateZ(0deg);
}

.swap-arrow-alt-Deposit {
    transition: 0.2s;
    transform: rotateZ(-90deg);
}

.swap-arrow-alt-Withdrawal {
    transition: 0.2s;
    transform: rotateZ(90deg);
}


.swap-arrow-Withdrawal:hover ,  .swap-arrow-Deposit:hover, .swap-arrow-alt-Deposit:hover, .swap-arrow-alt-Withdrawal:hover {
    cursor: pointer;
}