.addInvoice {
    display:flex;
    flex-direction: column;
  
  }

.addInvoiceMultiPage {
    display:flex;
    flex-direction: column;
}

.top-left{
    grid-area: top-left;
}

.top-middle-left{
    grid-area: top-middle-left;
}

.top-middle-right{
    grid-area: top-middle-right;
}

.top-right{
    grid-area: top-right;
    display: flex; 
    justify-content: flex-end;
}

.invoicePageHeader {
    display: flex;
    margin-left: auto;
    margin-right: auto; 
}

.invoiceButtons {
    display: flex;
    margin-left: auto;
}

.invoiceFormWrapper {
    margin-top: 15px;
    margin-bottom: 15px;
    margin-left: auto;
    margin-right: auto;
    width: 765px;
    height: 990px;
    box-shadow: 0 0 20px rgb(201, 201, 201);
}

.settingsIcon {
    transition: 0.5s;
}

.settingsIcon:hover {
    cursor: pointer;
    color: rgb(62, 62, 62);
    transition: 0.5s;
    transform: rotateZ(60deg);
}