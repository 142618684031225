.hoverableRow:hover  .rowDelete{
    opacity: 1 !important;
    cursor: pointer;
    color: black;
}

.hoverableRow .rowDelete:hover {
    color: #d11a2a;
}
.documentContact{
    padding: 1px;
}

.documentContact:hover{
    padding: 0px;
    border: 1px dashed rgb(127, 132, 135);;
    border-radius: 10px;
    cursor: pointer;

}
.documentContact:hover .documentContactLabel{
    
    opacity: 0.3;
    filter: blur(1px);
    pointer-events: none;

}
.documentContact:hover .testIcon{
    opacity: 1 !important;
}

.testIcon {
    text-align: center;
    opacity: 0;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    top: 40%;
}