.app{
  --ash-gray: #a1b0abff;
  --pakistan-green: #166600ff;
  --kombu-green: #3a4f41ff;
  --slimy-green: #3f9f19ff;
  --opal: #98b6b1ff;
  --light-moss: #cce2cd;
  
  --spanish-gray: #999999ff;
--jet: #313131ff;
--lime-green: #5fd450ff;
--screamin-green: #78f96fff;
}
  
  .tab{
    /* min-height:50px; */
    width:100%;
    padding:25px;
    font-family:sans-serif;
    color:#444;
  }

  ul.inline li:hover{
    border-bottom:6px solid #cce2cd;
    color:#444;

  }
  
  ul.inline{
    list-style:none;
    padding: 0;
    margin-bottom:0;
    -webkit-margin-before: 0;
    -webkit-margin-after: 0;
    -webkit-margin-start: 0px;
    -webkit-margin-end: 0px;
    -webkit-padding-start: 0px;
    
  }

  ul.inline li {
    display:inline-block;
    margin-left:0;
    padding: 10px 30px 10px 30px;
    border-bottom:6px solid #eee;
    transition: all .2s;
    font-family:sans-serif;
    font-weight:300;
    cursor:pointer;
    color:#aaa;
  }

  ul.inline li.selected{
    border-bottom:6px solid #90a790;
    color:#444;
  }