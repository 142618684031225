.tileButtonRaised {
    transition: 0.2s;
    width: 192px;
    height: 224px;
    border-radius: 12px;
    border: 1px solid white;
    box-shadow: 0 0 10px rgb(201, 201, 201);
    margin: 10px;
    background-color: white;
}
.tileButton {
    transition: 0.2s;
    width: 192px;
    height: 224px;
    border-radius: 12px;
    border: 1px solid rgb(180, 194, 203);
    margin: 10px;
    background-color: white;
}