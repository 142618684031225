.App {
  /* overflow-x: scroll;
  added
  white-space: nowrap; */
  white-space: nowrap;
}

:root {
  --sidebar-width: 255px;
  --settings-sidebar-width: 205px;
  /* color */
  --warning-color-dark: rgb(216, 190, 044);
  --warning-color-medium: rgb(255, 245, 189);
  --warning-color-light: rgb(255, 251, 230);
  --success-color-dark: rgb(0, 128, 0);
  --success-color-medium: rgb(194, 250, 194);
  --success-color-light: rgb(230, 255, 230);
  --error-color-dark: #d11a2a;
  --error-color-medium: rgb(255, 194, 194);
  --error-color-light: rgb(255, 230, 230);
  --info-color-dark: rgb(0, 0, 255);
  --info-color-medium: rgb(194, 194, 255);
  --info-color-light: rgb(230, 230, 255);

  --container-color: rgb(235, 239, 244);

  --background: rgb(255, 255, 255);
  --main-button-color: rgb(144, 167, 144);
  --main-button-color-hover: rgb(139, 201, 142);


  --main-color-1-0: #425F57;
  --main-color-1-1: #4E7D6A;
  --main-color-1-2: #5C9B7D;
  --main-color-1-3: #6AB98F;

  --main-color-2-0: #4E7D6A;
  --main-color-2-1: #5C9B7D;
  --main-color-2-2: #6AB98F;
  --main-color-2-3: #78D7A2;

  --main-color-3-0: #425F57;
  --main-color-3-1: #749F82;
  --main-color-3-2: #A8E890;
  --main-color-3-3: #CFFF8D;

  --main-color-4-0: #285430;
  --main-color-4-1: #5F8D4E;
  --main-color-4-2: #A4BE7B;
  --main-color-4-3: #E5D9B6;

  /* font */
  --font-size-normal: 18px;
  --font-size-small: 14px;
  --font-size-large: 22px;

  --disabled-font-color: rgb(150, 150, 150);
}


.dashboard,
.bills {
  display: flex;
  height: 90vh;
  align-items: center;
  justify-content: center;
  font-size: 3rem;
}

.contactSummary {
  display: flex;
  flex-direction: column;
  align-content: center;
  flex-wrap: wrap;
}

.page-container {
  margin-left: var(--sidebar-width);
}

@media (max-width: 1200px) {
  .productsList {
      width: 1000px;
  }

  .sidebarNav {
      display: none !important;
  }

  .page-container {
      margin-left: 0;
  }
}

.disabled-input:hover {
  cursor: not-allowed;
  
}

/* content editable placeholders */
[contenteditable=true]:empty:before {
  content: attr(placeholder);
  display: block;
  color: #aaa;
  font-style: italic;
}

[contenteditable=true]:hover {
  cursor: pointer;
}