.contactsContainer {
  display:grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-template-rows: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
  grid-template-areas: 
    "top-left top-middle-left top-middle-right top-right"
    "summaryReport summaryReport summaryReport summaryReport"
    "filterBar  filterBar filterBar filterBar"
    "contactTable contactTable contactTable contactTable"
    "contactTable contactTable contactTable contactTable"
    "contactTable contactTable contactTable contactTable"
    "contactTable contactTable contactTable contactTable"
    "contactTable contactTable contactTable contactTable"
    "contactTable contactTable contactTable contactTable"
    "contactTable contactTable contactTable contactTable"
    "contactTable contactTable contactTable contactTable"
    "contactTable contactTable contactTable contactTable"
  ;
  grid-gap: 0.2rem;
  position:relative;
    margin:0 auto;
    right:-100px;

  min-width: 1000px;
  max-width: 1000px;
  
  width: 100vh;
  height: 100vh;

}


.top-left{
    grid-area: top-left;
}

.top-middle-left{
    grid-area: top-middle-left;
}

.top-middle-right{
    grid-area: top-middle-right;
}

.top-right{
    grid-area: top-right;
    display: flex; 
    justify-content: flex-end;
}

.summaryReport {
    grid-area: summaryReport;
    height: 90%;
    display: flex;
    background-color: rgb(235, 239, 244);
    border-radius: 10px;
    justify-content: flex-start;
    align-items: flex-end;
    min-height: 100px;
}

.filterBar {
    grid-area: filterBar;
    display: flex;
    align-items: center;
}

.contactFilter {
    grid-area: contactFilter;
    margin-top: 50px;
}

.tableFooter {
    margin-top: 10px;
    display: flex;
    justify-content: space-between;
}

.contactTable{
    grid-area: contactTable;
    display: table;
    
}

.table{
    width: 100%;
}

table th {
    user-select: none;
}

.sortingTable thead tr th:not(:first-child):not(:last-child):hover span.unsorted:after {content:'▾'}
.sortingTable thead tr th:not(:first-child):not(:last-child):hover {color: rgb(019, 106, 205);}

.tableRows .rowButton{
    padding-top: 1px;
    padding-bottom: 1px;
    padding-right: 3px;
    padding-left: 3px;
    margin-left: 10px;
    margin-right: 10px;
}

.tableRows:hover  {
    background-color: rgb(216, 216, 216);
}
.tableRows:active  {
    background-color: rgb(216, 216, 216);
}
.hoverTable thead{
    border-bottom: solid;
    border-width: 3px 0;
    border-top: none;
}

.hoverTable tbody tr:last-child {
    border-bottom: solid;
    border-width: 1px 0;
    border-top: none;
  }

table td {
    white-space: nowrap;
    text-overflow: ellipsis;
}



.btn-light {
    background-color: rgb(235, 239, 244) !important
}

.btn {
    border-radius: 10px !important;
}

.link:hover {
    color: green;
    text-decoration: underline;
}