.listPage {
    width: 1400px;
    margin-left: auto;
    margin-right: auto;
    position: relative;
    right: -125px;
}

.summaryBar {
    display: flex;
    background-color: rgb(235, 239, 244);
    border-radius: 10px;
    justify-content: flex-start;
    align-items: flex-end;
    min-height: 100px;
}

@media (max-width: 1650px) {
    .listPage {
        width: 1250px;

    }
  
  }

@media (max-width: 1500px) {
    .listPage {
        width: 1150px;
    }
  }

@media (max-width: 1400px) {
    .listPage {
        right: -125px;
        width: 950px;
    }
  }

@media (max-width: 1200px) {
    .listPage {
        right: 0;
        width: 950px;
    }
  }