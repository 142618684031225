.underlineButton{
    color: #90a790;
    width: 50%;
}

.underlineButton:hover{
    
    color: #8bc98e;
    cursor: pointer;
    text-decoration: underline;
   
}