.chartOfAccounts {
    display:grid;
    grid-template-columns: 1.4fr 1fr 0.6fr 1.0fr;
    grid-template-rows: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
    grid-template-areas: 
      "top-left top-middle-left top-middle-right top-right"
      "accountTabs accountTabs accountTabs accountTabs"
      "contactTable contactTable contactTable contactTable"
      "contactTable contactTable contactTable contactTable"
      "contactTable contactTable contactTable contactTable"
      "contactTable contactTable contactTable contactTable"
      "contactTable contactTable contactTable contactTable"
      "contactTable contactTable contactTable contactTable"
      "contactTable contactTable contactTable contactTable"
      "contactTable contactTable contactTable contactTable"
      "contactTable contactTable contactTable contactTable"
    ;
    grid-gap: 0.2rem;
    position:relative;
    margin:0 auto;
    right:-125px;
    
    height: 100vh;
    background-color: rgb(247, 244, 244);
    
    min-width: 1000px;
    max-width: 1000px;
    white-space: nowrap;
  
  }

.top-left{
    grid-area: top-left;
}

.top-middle-left{
    grid-area: top-middle-left;
}

.top-middle-right{
    grid-area: top-middle-right;
}

.top-right{
    grid-area: top-right;
    display: flex; 
    justify-content: flex-end;
}
  
.accountTabs{
    grid-area: accountTabs;
    width: 100%;
    height: 100%;
    background-color: rgb(247, 244, 244);
}